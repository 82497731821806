<template>
    <div>
        <DesktopFaq v-if="!isMobile" />
        <MobileFaq v-else />
    </div>
</template>

<script>
import DesktopFaq from '@/components/Faq/Desktop/Faq'
import MobileFaq from '@/components/Faq/Mobile/Faq'
export default {
  components: {
    DesktopFaq,
    MobileFaq
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    this.$gtag.pageview(this.$route)
  }
}
</script>