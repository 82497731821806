<template>
<svg :width="width" :height="height"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12 22.0795C11.7 22.0795 11.4 22.0095 11.15 21.8695C9.28 20.8495 5.99 19.7695 3.93 19.4995L3.64 19.4595C2.33 19.2995 1.25 18.0695 1.25 16.7395V4.65952C1.25 3.86952 1.56 3.14952 2.13 2.62952C2.7 2.10952 3.44 1.85952 4.22 1.92952C6.42 2.09952 9.74 3.19952 11.62 4.37952L11.86 4.51952C11.93 4.55952 12.08 4.55952 12.14 4.52952L12.3 4.42952C14.18 3.24952 17.5 2.12952 19.71 1.93952C19.73 1.93952 19.81 1.93952 19.83 1.93952C20.56 1.86952 21.31 2.12952 21.87 2.64952C22.44 3.16952 22.75 3.88952 22.75 4.67952V16.7495C22.75 18.0895 21.67 19.3095 20.35 19.4695L20.02 19.5095C17.96 19.7795 14.66 20.8695 12.83 21.8795C12.59 22.0195 12.3 22.0795 12 22.0795ZM3.98 3.41952C3.66 3.41952 3.37 3.52952 3.14 3.73952C2.89 3.96952 2.75 4.29952 2.75 4.65952V16.7395C2.75 17.3295 3.26 17.8995 3.83 17.9795L4.13 18.0195C6.38 18.3195 9.83 19.4495 11.83 20.5395C11.92 20.5795 12.05 20.5895 12.1 20.5695C14.1 19.4595 17.57 18.3195 19.83 18.0195L20.17 17.9795C20.74 17.9095 21.25 17.3295 21.25 16.7395V4.66952C21.25 4.29952 21.11 3.97952 20.86 3.73952C20.6 3.50952 20.27 3.39952 19.9 3.41952C19.88 3.41952 19.8 3.41952 19.78 3.41952C17.87 3.58952 14.79 4.61952 13.11 5.66952L12.95 5.77952C12.4 6.11952 11.62 6.11952 11.09 5.78952L10.85 5.64952C9.14 4.59952 6.06 3.57952 4.1 3.41952C4.06 3.41952 4.02 3.41952 3.98 3.41952Z" fill="currentColor"/>
<path d="M12 21.2402C11.59 21.2402 11.25 20.9002 11.25 20.4902V5.49023C11.25 5.08023 11.59 4.74023 12 4.74023C12.41 4.74023 12.75 5.08023 12.75 5.49023V20.4902C12.75 20.9102 12.41 21.2402 12 21.2402Z" fill="currentColor"/>
<path d="M7.75 9.24023H5.5C5.09 9.24023 4.75 8.90023 4.75 8.49023C4.75 8.08023 5.09 7.74023 5.5 7.74023H7.75C8.16 7.74023 8.5 8.08023 8.5 8.49023C8.5 8.90023 8.16 9.24023 7.75 9.24023Z" fill="currentColor"/>
<path d="M8.5 12.2402H5.5C5.09 12.2402 4.75 11.9002 4.75 11.4902C4.75 11.0802 5.09 10.7402 5.5 10.7402H8.5C8.91 10.7402 9.25 11.0802 9.25 11.4902C9.25 11.9002 8.91 12.2402 8.5 12.2402Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
    name: "ActiveClassIconSidebar",
    props: {
      width: {
        type: String,
        default: "24"
      },
      height: {
        type: String,
        default: "24"
      }
    }
}
</script>