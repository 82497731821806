<template>
  <div>
    <div class="category-container" v-if="isCategory">
      <v-radio-group v-model="select">
        <v-radio
          v-for="(i, idx) in $utils.catgeories()"
          :key="idx"
          :label="`${i.name}`"
          :value="i.value"
          @click="() => (select = i.value)"
        ></v-radio>
      </v-radio-group>
      <div class="mt-5">
        <Button
          name="TERAPKAN"
          width="100%"
          height="40px"
          @click="onFilterClass"
        />
      </div>
    </div>

    <div class="container-body-mobile">
      <div class="card-schedule" v-for="i in assesments" :key="i.id">
        <div class="d-flex justify-space-between my-4">
          <div style="display: flex; gap: 15px">
            <div
              class="d-flex button-light-blue"
              v-if="i.category === 4"
              style="align-items: center; justify-content: center"
            >
              <img src="/img/other/ic_prakerja.png" width="69" height="22" />
            </div>
            <div class="button-light-blue py-2" v-if="i.category !== 4">
              <span style="font-size: 15px">Public Training</span>
            </div>
            <div class="button-light-blue py-2" v-else>
              <span style="font-size: 15px">Webinar</span>
            </div>
          </div>
          <div>
            <div
              class="button-grey"
              @click="goToPage(i.id, i.class_id)"
              style="width: 100px; font-size: 13px"
            >
              <span>Lihat Detail</span>
            </div>
          </div>
        </div>
        <div>
          <span class="title">{{
            i.name.length > 50 ? i.name.substring(0, 50) + ".." : i.name
          }}</span>
        </div>

        <div v-for="a in i.detail_assesments" :key="a.id">
          <div class="box-nilai">
            <span class="ttl">Pre Test</span>

            <div class="ctn">
              <span>{{ a.pre_test_score }}</span>
              <span
                :style="getColor(a.pre_test_score)"
                class="green-radius"
              ></span>
            </div>
          </div>

          <div class="box-nilai">
            <span class="ttl"
              >Quiz <span class="note-label">(Rata-rata Nilai)</span></span
            >

            <div class="ctn">
              <span>{{ a.quiz_score }}</span>
              <span :style="getColor(a.quiz_score)" class="green-radius"></span>
            </div>
          </div>
          <div class="box-nilai" v-if="i.category === 4">
            <span class="ttl"
              >TPM <span class="note-label">(Rata-rata Nilai)</span></span
            >

            <div class="ctn">
              <span>{{ a.tpm_score }}</span>
              <span :style="getColor(a.quiz_score)" class="green-radius"></span>
            </div>
          </div>

          <div class="box-nilai">
            <span class="ttl">Post Test</span>

            <div class="ctn">
              <span>{{ a.post_test_score }}</span>
              <span
                :style="getColor(a.post_test_score)"
                class="green-radius"
              ></span>
            </div>
          </div>

          <div class="box-nilai">
            <span class="ttl">Unjuk Keterampilan</span>

            <div class="ctn">
              <span>{{ a.final_project_score }}</span>
              <span
                :style="getColor(a.final_project_score)"
                class="green-radius"
              ></span>
            </div>
          </div>

          <div class="box-nilai">
            <span class="ttl">Catatan</span>
            <div style="margin-top: 10px">
              <span>{{ a.notes }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="button-filter" v-if="!isCategory">
        <Button
          name="KATEGORI"
          height="40px"
          width="80%"
          @click="() => (isCategory = true)"
          ><Grow
        /></Button>
      </div>
    </div>
  </div>
</template>

<script>
import Grow from "@/assets/icons/Grow.vue";
import Button from "../../others/Button.vue";
export default {
  data() {
    return {
      assesments_list: [],
      assesments: [],
      select: 0,
      isCategory: false,
    };
  },
  components: {
    Button,
    Grow,
  },
  methods: {
    getColor(e) {
      if (e <= 50) return "background:#B80F0A";
      else if (e >= 70 && e <= 79) return "background:#E5AF0B";
      else return "background:#01A368";
    },
    goToPage(e, class_id) {
      window.location.href = "/assesment/" + e + "/detail/" + class_id;
    },
    onFilterClass() {
      if (!this.select) this.assesments = this.assesments_list;
      else
        this.assesments = this.assesments_list.filter(
          (item) => item.category === this.select
        );
      this.isCategory = false;
    },
    async getAssesment() {
      await this.$http
        .get("/v1/user-assesment?user_id=" + this.$decode_token["id"])
        .then((response) => {
          this.assesments = response.data.data.assesments;
          this.assesments_list = response.data.data.assesments;
        })
        .catch((err) => console.error(err.response));
    },
  },
  mounted() {
    this.getAssesment();
  },
};
</script>

<style scoped>
.title {
  font-weight: bold;
  font-size: 15px !important;
  line-height: 20px;
  color: #36373f;
}

.green-radius {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #01a368;
  margin-left: 8px;
}
.note-label {
  color: #7b7e8c !important;
  font-style: italic;
  font-size: 10px;
  font-weight: 400 !important;
}
.box-nilai {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border: 2px solid #d3d6e1;
  border-radius: 10px;
  margin-top: 15px;
}
.box-nilai .ttl {
  font-size: 14px;
  font-weight: bold;
  color: #36373f;
}
.box-nilai .ctn {
  font-size: 15px;
  font-weight: 600;
  display: flex;
  gap: 8px;
  align-items: center;
}
</style>
