<template>
  <div>
    <div :class="`cursor-pointer card-schedule dekstop ${isMyClassPage && 'border-card'}`" @click="goToPage(data)">
      <div class="d-flex" style="align-items: flex-start" >
        <div class="b1">
          <img :src="data.image_url" width="110px" height="110px" style="border-radius: 24px" />
        </div>
        <div style="margin-left: 25px">
          <div class="container-label-top">
            <div class="label-top" style="background-color: #f5f6f9;" v-if="data.category === 4">
              <img src="/img/other/ic_prakerja.png" width="69" height="22" />
            </div>
            <div class="label-top" style="background-color: #f5f6f9;" v-if="data.category !== 4">
              {{ $utils.categoryName(data.category) }}
            </div>
            <div class="label-top" style="background-color: #FAECEB;">
              {{ data.learning_method | formatMethode }}
            </div>
            <div class="label-top" v-if="isTamat">
              <img src="/img/icons/ic_graduate.png" width="26" height="26" />
              <span style="margin-left: 5px">Tamat</span>
            </div>
          </div>
          <span class="title-card" id="font-poppins">{{ data.name }}</span>
          <div style="display: flex; gap: 32px; margin-top: 16px;" v-if="!isRaport">
            <div class="box-info-card">
              <LevelIcon :width="24" :height="24" />
              <span>{{ $utils.formatLevel(data.level) }}</span>
            </div>
            <div class="box-info-card">
              <CalendarIcon :width="24" :height="24" />
              <span>{{ data.dates }}, {{ data.hours }}</span>
            </div>
            <div class="box-info-card">
              <MediaIcon :width="24" :height="24" />
              <span>{{ $utils.formatMode(data.learning_method) }}</span>
            </div>
          </div>
          <div v-else class="mt-1" style="font-size: 14px;">
            <p>{{ data.date }}</p>
          </div>
          <div v-if="data.is_rating && !isTamat && !isRiwayat " class="container-chat mt-5"
            style="border: 1px solid #162CA2; width: max-content;" id="ulas"
            @click.stop="showReflectiveJournal(data)">
            <div class="content">
              <Message :width="25" fill="#162CA2" />
              <div class="content">
                <span style="color: #162CA2;">Kamu sudah isi reflective journal, yuk lihat di sini!</span>
              </div>
            </div>
          </div>
          <div class="button-warning mt-5" v-else-if="
            ((data.sequence >= 5 && !data.is_rating) || isTester) && !isTamat
          " id="ulas" @click.stop="tulisUlasan(data)">
            <InformationWarningIcon :width="24" :height="24" />
            <span>Lengkapi Survey & Ulasan sebagai syarat penerbitan sertifikat kelas pelatihan!</span>
          </div>
        </div>
      </div>
      <div class="container-chat" v-if="data.is_rating && isRiwayat && !isTamat" >
        <div class="content" style="gap: 20px; padding: 4px 0; align-items: flex-start !important">
          <div>
            <div class="name-circle d-flex">
              <span>{{ data.initial_name }}</span>
            </div>
          </div>
          <div>
            <StarTemplate :starCount="data.stars" />
            <p class="mt-1">{{ data.feedback }}</p>
            <span class="date-ratting">{{
              $utils.dateFormat(data.ratting_date)
            }}</span>
          </div>
        </div>
      </div>

    </div>
    <div class="card-schedule mobile">
      <div @click="goToPage(data)">
        <div class="d-flex">
          <div><img :src="data.image_url" class="img-class" /></div>
          <div style="margin-left: 20px">
            <div class="d-flex button-light-blue" v-if="data.category === 4"
              style="align-items: center; justify-content: center">
              <img src="/img/other/ic_prakerja.png" width="69" height="22" />
            </div>
            <div class="button-light-blue" v-if="data.category !== 4">
              <span>Public Training</span>
            </div>
            <h3 class="title-2">
              {{
                data.name.length > 50
                  ? data.name.substring(0, 50) + "..."
                  : data.name
              }}
            </h3>
          </div>
        </div>
        <div class="info-class">
          <div class="info-class-ctn">
            <img src="/img/icons/ic_level.png" />
            <span>{{ $utils.formatLevel(data.level) }}</span>
          </div>
          <div class="info-class-ctn">
            <img src="/img/icons/ic_date.png" />
            <span>{{ data.dates }}, {{ data.hours }}</span>
          </div>
          <div class="info-class-ctn">
            <img src="/img/icons/ic_training_method.png" />
            <span>{{ data.learning_method | formatMethode }}</span>
          </div>
        </div>
      </div>
      <div class="container-chat" v-if="data.is_rating && isRiwayat && !isTamat" @click="goToPage(data)">
        <div class="content" style="gap: 20px; padding: 4px 0; align-items: flex-start !important">
          <div>
            <div class="name-circle d-flex">
              <span>{{ data.initial_name }}</span>
            </div>
          </div>
          <div>
            <StarTemplate :starCount="data.stars" />
            <p class="mt-1">{{ data.feedback }}</p>
            <span class="date-ratting">{{
              $utils.dateFormat(data.ratting_date)
            }}</span>
          </div>
        </div>
      </div>
      <div v-if="data.is_rating && !isRiwayat && !isTamat" class="container-chat"
        style="border: 1px solid #162CA2;" id="ulas" @click="showReflectiveJournal(data)">
        <div class="content">
          <Message :width="25" fill="#162CA2" />
          <div class="content">
            <span style="font-size: 12px; color: #162CA2;">Kamu sudah isi reflective journal, yuk lihat di sini!</span>
          </div>
        </div>
      </div>
      <div class="container-chat" v-else-if="
        ((data.sequence >= 5 && !data.is_rating) || isTester) && !isTamat
      " id="ulas" @click="tulisUlasan(data)">
        <div class="content">
          <Message :width="25" />
          <div class="content">
            <span style="font-size: 12px">Isi reflective journal & ulasan yuk!</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import StarTemplate from "./StarTemplate.vue";
import LevelIcon from "@/assets/icons/Level.vue";
import CalendarIcon from "@/assets/icons/Calendar.vue";
import MediaIcon from "@/assets/icons/Media.vue";
import InformationWarningIcon from "@/assets/icons/InformationWarning.vue";
import Message from "@/assets/icons/Message.vue";


export default {
  name: "CardClass",
  props: {
    data: {
      type: Object,
      required: true,
    },
    isTester: {
      type: Boolean,
      default: false,
    },
    isMyClassPage: {
      type: Boolean,
      default: false,
    },
    isRaport: {
      type: Boolean,
      default: false,
    },
    isTamat: {
      type: Boolean,
      default: false,
    },
    isRiwayat: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    StarTemplate,
    LevelIcon,
    CalendarIcon,
    MediaIcon,
    InformationWarningIcon,
    Message
  },
  methods: {
    goToPage(item) {
      if (this.isRiwayat) {
        window.location.href = `/survey/${item.class_id}?user_id=${this.$decode_token["id"]}`;
        return;
      }
      if (this.isRaport) {
        window.location.href = `assesment/${this.$decode_token["id"]}/detail/${item.class_id}`;
        return
      }
      window.location.href = `/class/${item.class_id}/session`;
    },
    tulisUlasan(item) {
      window.location.href = `/survey/${item.class_id}`;
    },
    showReflectiveJournal(item) {
      window.location.href = `/survey/${item.class_id}?user_id=${this.$decode_token["id"]}`;
    },
  },
};
</script>
<style scoped>
.container-chat {
  margin-top: 10px;
  padding: 5px 19px;
  border-radius: 12px;
  position: relative;
  z-index: 10px;
  border: 1px solid #d3d6e1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date-ratting {
  font-size: 15px;
}

.container-chat .content {
  display: flex;
  gap: 8px;
  align-items: center !important;
}

.dekstop {
  display: block;
}

.mobile {
  display: none;
}

.name-circle {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(111.75deg, #179eff -0.58%, #162ca2 100.82%);
}

.b1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.border-card {
  border: 1px solid #D3D6E1;
}

.container-label-top {
  display: flex;
  align-items: center;
  gap: 10px;
}

.label-top {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F1F6FF;
  padding: 7px 12px;
  border-radius: 24px;
  color: #36373F;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.title-card {
  margin-top: 10px;
  display: block;
  color: #36373F;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.box-info-card {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.box-info-card span {
  color: #36373F;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.button-warning {
  margin-top: 16px;
  border: 1px solid #B80F0A;
  border-radius: 10px;
  padding: 8px 18px;
  display: flex;
  align-items: center;
  width: max-content;
  gap: 12px;
  color: #B80F0A;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

@media only screen and (max-width: 920px) {
  .dekstop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .img-class {
    height: 80px;
    width: 80px;
    border-radius: 16px;
  }

  .title-2 {
    font-weight: bold;
    font-size: 15px !important;
    line-height: 19px !important;
    color: #36373f;
    margin-top: 15px;
  }

  .date-ratting {
    font-size: 12px;
  }
}
</style>
