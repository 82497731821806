<template>
    <DekstopReview v-if="!isMobile" />
    <MobileReview v-else />
</template>

<script>
import DekstopReview from '@/components/Review/Dekstop/Review.vue';
import MobileReview from '@/components/Review/Mobile/Review.vue';

export default {
    name: "ReviewPage",
    components: {
        DekstopReview,
        MobileReview
    },
    data() {
        return {
            isMobile: false
        }
    },
    methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    this.$gtag.pageview(this.$route)
  }
}
</script>