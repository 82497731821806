<template>
  <div>
    <DesktopSessionClass v-if="!isMobile" />
    <MobileSessionClass v-else />
  </div>
</template>

<script>
import DesktopSessionClass from "@/components/SessionClass/Desktop/SessionClass";
import MobileSessionClass from "@/components/SessionClass/Mobile/SessionClass";
export default {
  components: {
    DesktopSessionClass,
    MobileSessionClass
  },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    handleView() {
      this.isMobile = screen.width <= 990;
    },
  },
  beforeMount() {
    this.handleView();
    this.$gtag.pageview(this.$route);

  },
};
</script>
