<template>
    <DekstopSurvey v-if="!isMobile" />
    <MobileSurvey v-else />
</template>

<script>
import DekstopSurvey from '@/components/Survey/Dekstop/Survey.vue';
import MobileSurvey from '@/components/Survey/Mobile/Survey.vue';

export default {
    name: "SurveyPage",
    components: {
        DekstopSurvey,
        MobileSurvey
    },
    data() {
        return {
            isMobile: false
        }
    },
    methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    this.$gtag.pageview(this.$route);
  }
}
</script>