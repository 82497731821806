<template>
  <div>
    <div class="category-container" v-if="isCategory">
      <v-radio-group v-model="select">
        <v-radio
          v-for="(i, idx) in $utils.catgeories()"
          :key="idx"
          :label="`${i.name}`"
          :value="i.value"
          @click="() => (select = i.value)"
        ></v-radio>
      </v-radio-group>
      <div class="mt-5">
        <Button
          name="TERAPKAN"
          width="100%"
          height="40px"
          @click="onFilterClass"
        />
      </div>
    </div>


  <div class="container-body-mobile">
        <div v-for="item in classes" :key="item.class_id">
          <CardClass :data="item" :isTamat="true" />
        </div>
        <div class="button-filter" v-if="!isCategory">
        <Button
          name="KATEGORI"
          height="40px"
          width="80%"
          @click="() => (isCategory = true)"
          ><Grow
        /></Button>
      </div>
      </div>
  </div>
</template>

<script>
import Button from "../../others/Button.vue";
import Grow from "@/assets/icons/Grow.vue";
import CardClass from "../../others/CardClass.vue";

export default {
  data() {
    return {
      isCategory: false,
      classes_list: [],
      classes: [],
      select: 0,
    };
  },
  components: {
    Button,
    Grow,
    CardClass
  },
  methods: {
    onFilterClass() {
      if (!this.select) this.classes = this.classes_list;
      else
        this.classes = this.classes_list.filter(
          (item) => item.category === this.select
        );
      this.isCategory = false;
    },
    async getClass() {
      this.classes = [];
      this.classes_list = [];
      await this.$http
        .get(
          "/v1/user-schedule?user_id=" +
            this.$decode_token["id"] +
            "&status=false"
        )
        .then((response) => {
          for (let i = 0; i < response.data.data.schedules.length; i++) {
            const item = response.data.data.schedules[i];
            this.classes.push({...item});
            this.classes_list.push({...item});
          }
        })
        .catch((err) => console.error(err.response));
    },
  },
  mounted() {
    this.getClass();
  },
};
</script>

<style scoped>
.history-class-card {
  background: #ffffff;
  border-radius: 24px;
  padding: 20px !important;
  width: 100%;
  position: relative;
  margin-right: 5px;
  margin-top: 12px;
  box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1);
}

.info-classes {
  margin-left: 20px;
  font-size: 11px;
  width: 70%;
}

.title {
  font-weight: 700;
  font-size: 15px !important;
  line-height: 22px;
  color: #36373f;
}
</style>
