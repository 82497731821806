<template>
    <div>
        <DesktopQuestion v-if="!isMobile" />
        <!-- <MobileQuestion v-if="isMobile" /> -->
    </div>
</template>

<script>
import DesktopQuestion from '@/components/Question/Desktop/QuestionFinish'
// import MobileQuestion from '@/components/Question/Mobile/QuestionFinish'
export default {
  components: {
    DesktopQuestion,
    // MobileQuestion
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    this.$gtag.pageview(this.$route)
  }
}
</script>