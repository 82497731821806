import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueGtag from "vue-gtag";

import helpers from "@/utils/helpers.js";
import VueJwtDecode from 'vue-jwt-decode';
import utils from './utils/utils'
import { webAPI, crmAPI, lmsAPI } from './utils/api'

const plugins = {
  install() {
      Vue.helpers = helpers;
      Vue.prototype.$helpers = helpers;
  }
}
Vue.use(plugins);
Vue.use(VueGtag, {
  config: { id: "G-V0SX6RXM1L" },
}, router);

Vue.config.productionTip = false

Vue.prototype.$http = lmsAPI
Vue.prototype.$web_http = webAPI
Vue.prototype.$crm_http = crmAPI

Vue.prototype.$utils = utils


Vue.filter('formatMethode', function (value) {
  if (!value) return value
  if (value.toLowerCase() === "online") {
    return "Daring - Webinar"
  } else if  (value.toLowerCase() === "offline") {
    return "Luring"
  } else {
    return value
  }
});

if(localStorage.getItem("user")) {
  var data = localStorage.getItem("user")
  let decoded = VueJwtDecode.decode(data)
  Vue.prototype.$decode_token = decoded
} else {
  console.log('');
}

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')