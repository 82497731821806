<template>
    <div class="container-custom-body">
        <div style="padding: 16px 24px" class="border-gray d-flex">
      <div style="margin-right: 16px; padding-top: 5px">
        <Info />
      </div>

      <div>
        <h3 style="color: #36373f; font-weight: 700;">
          Syarat Mendapatkan Sertifikat
        </h3>
        <div class="mt-2" style="font-size: 15px;">
            <p>Peserta wajib menyelesaikan seluruh rangkaian pelatihan baik dari mengikuti pelatihan Webinar, mengerjakan Evaluasi (<b>Pre-Test</b>, <b>Quiz</b>, <b>Post-Test</b>, <b>Tes Praktek Mandiri</b> & <b>Tes Uji Keterampilan</b>)</p>
            <p>Sertifikat akan diterbitkan maximal <b>7x24 jam</b></p>
            <p>Kamu dapat mengunduh sertifikat dengan klik gambar atau klik Lihat Detail pada sertifikat yang dipilih</p>
        </div>
      </div>
    </div>
        <div class="container-class">
      <div class="d-flex" style="align-items: center">
        <div class="justify-start" style="width: 100%">
          <h4 class="class-schedule-text">Sertifikatku</h4>
        </div>
        <div class="d-flex justify-end" style="align-items: center;">
                <span class="category-text">Kategori</span>
                <div style="width:250px">
                    <v-select
                        v-model="category"
                        :items="$utils.catgeories()"
                        item-text="name"
          item-value="value"
                        dense
                        outlined
                        hide-details
                        class="ma-2"
                    ></v-select>
                </div>
            </div>
        </div>
        <EmptyData v-if="!certificates.length" />

        <div class="certificates">
            <div class="cardss" v-for="(n, idx) in certificates" :key="idx" @click="goToLink(n.file_url)">
                <div class="d-flex button-light-blue label-category" v-if="n.category === 4" style="align-items:center;justify-content:center"><img src="/img/other/ic_prakerja.png" width="69" height="22"></div>
                        <div class="button-light-blue label-category" v-if="n.category !== 4"><span>{{ $utils.categoryName(n.category) }}</span></div>
                        <img src="/img/prakerja/default_certificate.png" class="ctn">
                        <div class="title"><span>{{ n.name.length > 50 ? n.name.substring(0,50)+".." : n.name }}</span></div>
            </div>
        </div>
        </div>
    </div>
</template>

<script>
import Info from "@/assets/icons/Info.vue";
import EmptyData from '@/components/Empty.vue';
export default {
    components: { Info, EmptyData },
    data () {
        return {
            certificates_list: [],
            certificates: [],
            category: 0
        }
    },
    watch: {
    category(v) {
      if (!v) this.certificates = this.certificates_list 
      else this.certificates = this.certificates_list.filter((item) => item.category === v)
    }
  },
    methods: {
        async getCertificates() {
            await this.$http
                .get("/v1/user-certificate?user_id="+ this.$decode_token["id"])
                .then((response) => {
                    this.certificates = response.data.data.certificates
                    this.certificates_list = response.data.data.certificates
                })
            .catch((err) => console.error(err.response))
        },
        goToLink(e) {
            window.open(
                e,
                '_blank' // <- This is what makes it open in a new window.
            )
        },
    },
    mounted() {
        // var data = JSON.parse(localStorage.getItem("user"))
        // this.user_id = data.id
        this.getCertificates()
    }
}
</script>

<style scoped>
.class-schedule-text {
  color: #36373f;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
}
.container-class {
  background-color: white;
  padding: 40px 32px;
  border-radius: 24px;
  margin-top: 16px;
}
.certificate-card {
    border-radius:10px;
    cursor:pointer;
    width: 500px;
    height: 380px;
}
.notification-info {
    padding: 20px 30px;
    background-color: #F1F6FF;
    display: flex;
    align-items: flex-start;
    gap: 30px;
}

.title {
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    margin: 25px 20px;    
    color: #36373F;
}

.label-category {
    position: absolute;
    right: 20px;
    top: 20px;
}
.certificates {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
}
.certificates .cardss {
    box-shadow: 2px 2px 10px 3px rgba(13, 28, 113, 0.1) !important;
    border-radius: 24px;
    cursor: pointer;
    padding: 0px !important;
    position: relative;
    background-color: white;
}
.border-gray {
  border: 1px solid #d3d6e1;
  border-radius: 16px;
}
.certificates .ctn {
    width: 100%;
    border-radius: 14px 14px 0 0 ;
    height: 200px;
    object-fit: cover;
}
@media only screen and (max-width: 990px) {
    .certificates { 
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>