<template>
    <div class="container-custom-body">
      <Loading v-if="isLoading" />
     
      <div
        class="d-flex justify-end"
        style="margin-bottom: 40px; align-items: center"
      >
        <span>Kategori</span>
        <div style="width: 250px">
          <v-select
            v-model="category"
            :items="$utils.catgeories()"
            item-text="name"
            item-value="value"
            dense
            outlined
            hide-details
            class="ma-2"
          ></v-select>
        </div>
      </div>
      <div v-for="item in schedules" :key="item.class_id">
        <CardClass :data="item" :isRiwayat="true" />
      </div>
      <EmptyData v-if="!schedules.length" />

    </div>
  </template>
  
  <script>
  import CardClass from "../../others/CardClass.vue";
  import Loading from "../../others/Loading.vue";
  import EmptyData from '@/components/Empty.vue';

  export default {
    components: {
      CardClass,
      Loading,
      EmptyData
    },
    data() {
      return {
        schedules: [],
        schedules_list: [],
        category: 0,
        isLoading: false,
      };
    },
    watch: {
      category(v) {
        if (!v) this.schedules = this.schedules_list;
        else
          this.schedules = this.schedules_list.filter(
            (item) => item.category === v
          );
      },
    },
    methods: {
      async getClass() {
        this.isLoading = true
        this.schedules = [];
        this.schedules_list = [];
        await this.$http
          .get(
            "/v1/user-schedule/review?user_id=" + this.$decode_token["id"]
          )
          .then(async (response) => {
            for (let i = 0; i < response.data.data.schedules.length; i++) {
              const item = response.data.data.schedules[i];
              this.schedules.push(item);
              this.schedules_list.push(item);
            }
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          });
      },
    },
    mounted() {
      this.getClass();
    },
  };
  </script>
  