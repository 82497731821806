<template>

<svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.9999 22.2498C11.1799 22.2498 10.3599 21.9898 9.6799 21.4798L4.46991 17.5798C3.49991 16.8498 2.91992 15.6898 2.91992 14.4798V1.75977H21.0799V14.4798C21.0799 15.6898 20.4999 16.8498 19.5299 17.5798L14.3199 21.4798C13.6399 21.9898 12.8199 22.2498 11.9999 22.2498ZM4.41992 3.24977V14.4698C4.41992 15.2098 4.7799 15.9198 5.3699 16.3698L10.5799 20.2698C11.4199 20.8998 12.5899 20.8998 13.4299 20.2698L18.6399 16.3698C19.2299 15.9198 19.5899 15.2098 19.5899 14.4698V3.24977H4.41992Z" fill="currentColor"/>
<path d="M22 3.25H2C1.59 3.25 1.25 2.91 1.25 2.5C1.25 2.09 1.59 1.75 2 1.75H22C22.41 1.75 22.75 2.09 22.75 2.5C22.75 2.91 22.41 3.25 22 3.25Z" fill="currentColor"/>
<path d="M16 8.75H8C7.59 8.75 7.25 8.41 7.25 8C7.25 7.59 7.59 7.25 8 7.25H16C16.41 7.25 16.75 7.59 16.75 8C16.75 8.41 16.41 8.75 16 8.75Z" fill="currentColor"/>
<path d="M16 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H16C16.41 12.25 16.75 12.59 16.75 13C16.75 13.41 16.41 13.75 16 13.75Z" fill="currentColor"/>
</svg>

</template>

<script>
export default {
    name: "Certificate",
    props: {
    width: {
      type: String,
      default: "24"
    },
    height: {
      type: String,
      default: "24"
    }
  }
}
</script>