<template>
    <div>
        <DesktopDetailClass v-if="!isMobile" />
        <MobileDetailClass v-else />
    </div>
  </template>
  
  <script>
  import DesktopDetailClass from '@/components/DetailClass/Desktop/DetailClass'
  import MobileDetailClass from '@/components/DetailClass/Mobile/DetailClass'
  export default {
    components: {
      DesktopDetailClass,
      MobileDetailClass
    },
    data() {
      return{
        isMobile: false
      }
    },
    methods: {
        handleView() {
            this.isMobile = screen.width <= 990
        }
    },
    beforeMount() {
      this.handleView()
      this.$gtag.pageview(this.$route);
    }
  }
  </script>