<template>
  <div class="container-custom">
    <div class="list-scrumb">
      <div v-for="(item, idx) in items" :key="idx" class="list-scrumb ml-4 cursor-pointer"
        @click="$router.push(item.href)">
        <span>{{ item.text }}</span>
        <arrow strokeColor="#7B7E8C" direct="right" />
      </div>
      <span class="title-scrumb">{{
        this.$helpers.maxStringLength("Rapor Kelas", 60)
      }}</span>
    </div>
    <div class="d-flex" style="margin-top: 45px; margin-left: 10px">
      <div>
        <img :src="classDetail.image_url ? classDetail.image_url : '/img/logo-hc.png'" width="100" height="100"
          style="border-radius: 16px" />
      </div>
      <div style="margin-left: 30px">
        <div class="container-label-top">
          <div class="label-top" style="background-color: #f1f6ff" v-if="classDetail.category !== 4">
            {{
              $utils.categoryName(classDetail.category)
            }}
          </div>
          <div class="badge" v-if="classDetail.category === 4">
            <img src="/img/other/ic_prakerja_new.png" width="69" height="22" />
          </div>
          <div class="label-top" style="background-color: #faeceb">
            {{ classDetail.learning_method | formatMethode }}
          </div>
        </div>

        <div>
          <h2>
            {{ classDetail.name }}
          </h2>
        </div>

        <div class="d-flex" style="margin-top: 16px">
          <div class="box-info-card">
            <LevelIcon :width="24" :height="24" />
            <span class="box-info-card-span">
              {{ $utils.formatLevel(classDetail.level) }}
            </span>
          </div>
          <div class="box-info-card" style="margin-left: 20px">
            <CalendarIcon :width="24" :height="24" />
            <span class="box-info-card-span">
              {{ classDetail.dates }}, {{ classDetail.hours }}
            </span>
          </div>
          <div class="box-info-card" style="margin-left: 20px">
            <MediaIcon :width="24" :height="24" />
            <span class="box-info-card-span">{{ $utils.formatMode(classDetail.learning_method)  }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container-radius">
      <div class="d-flex">
        <h3>Rapor Kelas</h3>
        <InformationForm />
      </div>
      <span>Nilai yang berhasil HiColers selesaikan dalam pelatihan ini      </span>

      <div class="bg-ghost-white d-flex" style="align-items: center; margin-top: 50px">
        <div style="width: 100%">
          <h3>PreTest</h3>
          <span>Tes kemampuan dasar sebelum mengikuti pelatihan</span>
        </div>
        <div class="test-score">
          <h3>{{ assesment.pre_test_score }}</h3>
        </div>
        <div v-if="classDetail.category === 4" :style="getColor(assesment.pre_test_score)" class="green-radius"></div>
      </div>

      <div class="bg-ghost-white" style="margin-top: 20px">
        <div class="d-flex" style="align-items: center; margin-bottom: 20px">
          <div style="width: 100%">
            <div class="d-flex" style="align-items: center">
              <h3>Quiz <span style="color: #7B7E8C; font-weigth: 400; font-size: 14px;">(Rata-rata nilai)</span></h3>
            </div>
            <span>Tes untuk mengukur kemampuan & kepahaman terhadap materi
              pelatihan</span>
          </div>
          <div class="test-score">
            <h3>{{ assesment.quiz_score }}</h3>
          </div>
          <div v-if="classDetail.category === 4" :style="getColor(assesment.quiz_score)" class="green-radius"></div>
        </div>

        <div style="padding: 10px 50px; max-width: 96%">
          <div v-for="(item, idx) in assesment.assesment_quiz" :key="idx">
            <div class="d-flex detail-quizz">
              <h4 style="width: 100%">Quiz Sesi {{ idx + 1 }}</h4>
              <span class="test-score">{{ item.score }}</span>
            </div>
            <div class="long-line"></div>
          </div>
        </div>
      </div>

      <div class="bg-ghost-white" style="margin-top: 20px">
        <div class="d-flex" style="align-items: center; margin-bottom: 20px">
          <div style="width: 100%">
            <div class="d-flex" style="align-items: center">
              <h3>Tugas Praktik Mandiri <span style="color: #7B7E8C; font-weigth: 400; font-size: 14px;">(Rata-rata
                  nilai)</span></h3>
            </div>
            <span>Tes evaluasi untuk mengetahui tingkat penyerapan ilmu melalui
              tugas praktik</span>
          </div>
          <div class="test-score">
            <h3>{{ assesment.tpm_score }}</h3>
          </div>
          <div v-if="classDetail.category === 4" :style="getColor(assesment.tpm_score)" class="green-radius"></div>
        </div>
        <div style="padding: 10px 50px; max-width: 96%">
          <div v-for="(item, idx) in assesment.assesment_tpm" :key="idx">
            <div class="d-flex detail-quizz">
              <h4 style="width: 100%">Tugas Praktik Mandiri {{ idx + 1 }}</h4>
              <span class="test-score">{{ item.score }}</span>
            </div>
            <span><b>Catatan</b>: <span style="color: #b80f0a;">{{ item.note }}</span></span>
            <div class="long-line"></div>
          </div>
        </div>
      </div>

      <div class="bg-ghost-white d-flex" style="align-items: center; margin-top: 20px">
        <div style="width: 100%">
          <h3>Post-Test</h3>
          <span>Tes evaluasi untuk mengetahui tingkat penyerapan ilmu & pemahaman
            seluruh materi pelatihan</span>
        </div>
        <div class="test-score">
          <h3>{{ assesment.post_test_score }}</h3>
        </div>
        <div v-if="classDetail.category === 4" :style="getColor(assesment.post_test_score)" class="green-radius"></div>
      </div>

      <div class="bg-ghost-white d-flex" style="align-items: center; margin-top: 20px">
        <div style="width: 100%">
          <h3>Unjuk Keterampilan</h3>
          <span>Evaluasi performa HiColers melalui karya Unjuk Keterampilan sebagai
            pendukung kompetensi pelatihan</span>
        </div>
        <div class="test-score">
          <h3>{{ assesment.final_project_score }}</h3>
        </div>
        <div v-if="classDetail.category === 4" :style="getColor(assesment.final_project_score)" class="green-radius"></div>
      </div>

      <div class="bg-ghost-white" style="margin-top: 20px">
        <div style="width: 100%">
          <h3>Catatan</h3>
          <span v-if="!assesment.notes">-</span>
          <span v-html="assesment.notes"></span>
        </div>
      </div>
    
    </div>
    <div class="container-radius">
      <div class="d-flex">
        <div style="width: 100%">
          <h3>Presensi Kelas</h3>
          <span>Data kehadiran peserta dalam mengikuti kelas pelatihan</span>
        </div>
      </div>
      <div style="margin-top: 32px; margin-bottom: 24px">
        <div
          v-for="(item, idx) in attendance"
          :key="idx"
          style="margin-top: 16px"
          class="presensi"
        >
          <div>
            <h2 style="font-size: 16px; font-weight: 600; line-height: 22px">
              Sesi {{ item.sequence }} : {{ classDetail.syllabuses[idx].name_title }}
            </h2>
            <div class="box-presensi-card">
              <CalendarIcon :width="24" :height="24" />
             
              <span class="box-presensi-card-span" v-if="classDetail.category === 4">
                {{ $utils.dateFormat(item.start_date, "DD MMM YYYY", "") }}, {{ classDetail.hours }} 
              </span>
              <span class="box-presensi-card-span" v-else>
                {{ $utils.dateFormat(classDetail.session_schedule[idx].date_start, "DD MMM YYYY", "") }}, {{ classDetail.session_schedule[idx].start_time }} - {{ classDetail.session_schedule[idx].end_time }} WIB
              </span>
            </div>
          </div>
          <div style="margin-left: auto" class="presensi-btn">
            <h2 class="persensi-btn-text">
              Hadir
            </h2>
            <div class="hr"></div>
            <h2 class="persensi-btn-text">{{$utils.dateFormat(item.start_date, "HH:mm", "")}}</h2>
          </div>
          <div style="margin-left: 16px" class="presensi-btn">
            <h2 class="persensi-btn-text">
              Keluar 
            </h2>
            <div class="hr"></div>
            <h2 class="persensi-btn-text">{{$utils.dateFormat(item.end_date, "HH:mm", "")}}</h2>
          </div>
        </div>
      </div>
</div>
  </div>
</template>

<script>
import InformationForm from "@/components/Assesment/Desktop/Information";
import Arrow from "@/assets/icons/Arrow.vue";
import LevelIcon from "@/assets/icons/Level.vue";
import CalendarIcon from "@/assets/icons/Calendar.vue";
import MediaIcon from "@/assets/icons/Media.vue";

export default {
  components: {
    InformationForm,
    Arrow,
    LevelIcon,
    CalendarIcon,
    MediaIcon,
  },
  data() {
    return {
      items: [
        {
          text: "Penilaian",
          disabled: false,
          href: "/assesment",
        },
      ],
      attendance: [],
      assesment: {},
      classDetail: {}
    };
  },
  methods: {
    getColor(e) {
      if (e <= 50) return "background:#B80F0A";
      else if (e >= 70 && e <= 79) return "background:#E5AF0B";
      else return "background:#01A368";
    },
    async getDetailAssesment() {
      await this.$http
        .get(`/v2/user-assesment/detail?user_id=${this.$decode_token["id"]}&class_id=${this.$route.params.class_id}`)
        .then((response) => {
          const data = response.data.data.assesment
          this.assesment = data;
          if (!this.assesment.assesment_tpm) {
            this.assesment.assesment_tpm = [];
          }
        })
        .catch((err) => console.error(err.response));
    },
    
    async getDetailClass() {
      await this.$http
        .get(`/v1/user-schedule/detail?user_id=${this.$decode_token["id"]}&class_id=${this.$route.params.class_id}`)
        .then((response) => {
          this.classDetail = response.data.data.schedule;
          this.classDetail.syllabuses = this.classDetail.syllabuses.map((item) => {
            return {
              ...item,
              name_title: item.description.split("</li>")[0].replace("<ul>", "").replace("<li>", ""),
            };
          }); 
        })
        .catch((err) => console.error(err.response));
    },
    async getAttandance() {
      await this.$http
        .get(`/v1/attendance/user/${this.$decode_token["id"]}/class/${this.$route.params.class_id}/history`)
        .then((response) => {
          this.attendance = response.data.data.attendances;
        })
        .catch((err) => console.error(err.response));
    },
  },
  mounted() {
    this.getDetailAssesment();
    this.getDetailClass()
    this.getAttandance()
  },
};
</script>

<style scoped>
.green-radius {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
  background: #01a368;
  margin-left: 20px;
}

.detail-quizz {
  padding: 10px 0px;
  align-items: center;
}

.badges {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.badge {
  display: flex;
  background-color: #f5f6f9;
  border-radius: 20px;
  height: 30px;
  padding: 0px 16px;
  align-items: center;
}

.badge span {
  color: #162ca2;
}

.list-scrumb {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: bold;
  color: #162ca2;
  font-size: 16px;
}

.title-scrumb {
  color: #7b7e8c;
  font-weight: 400;
}

.download {
  margin-left: 48px;
}

.box-presensi-card-span {
  margin-left: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.box-info-card-span {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
}

.title {
  font-weight: 700;
  line-height: 30px;
}

.container-label-top {
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
}

.label-top {
  background-color: #f1f6ff;
  padding: 5px 12px;
  border-radius: 24px;
  color: #36373f;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.box-presensi-card {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
}

.box-info-card {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.test-score {
  width: 64px;
  height: 64px;
  background-color: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  color: #36373f;
  font-weight: 600;
  font-size: 18px;
}
.presensi {
  width: 100%;
  padding: 24px 32px;
  background-color: #f7f9fd;
  border-radius: 16px;
  display: flex;
  align-items: center;
}
.absent {
  align-items: center;
  justify-content: center;
  color: #36373f;
  padding: 20px 40px;
}

.persensi-btn-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.presensi-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 208px;
  height: 48px;
  gap: 10px;
  border-radius: 12px;
}
.hr {
  border-left: 1px solid #7b7e8c;
  height: 27px;
  margin: 0
}
</style>
