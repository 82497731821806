<template>
    <div>
        <DesktopCertificate v-if="!isMobile" />
        <MobileCertificate v-else />
    </div>
</template>

<script>
import DesktopCertificate from '@/components/Certificate/Desktop/Certificate'
import MobileCertificate from '@/components/Certificate/Mobile/Certificate'
export default {
  components: {
    DesktopCertificate,
    MobileCertificate
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    this.$gtag.pageview(this.$route);
  }
}
</script>


<!-- <template>
    <div class="pa-10">
        <h2>Sertifikat</h2>

        <div class="mt-5"><span>Kamu dapat mengunduh sertifikat dengan klik gambar sertifikat yang dipilih</span></div>
        <v-row no-gutters class="mt-10">
            <template v-for="n in certificates">
                <v-col :key="n.id" cols="4">
                    <v-card
                        class="mt-10"
                        style="border-radius:10px;cursor:pointer;" @click="goToLink(n.file_url)">
                        <img :src="n.image_url" width="500" height="300">
                    </v-card>
                </v-col>
                <v-responsive
                    v-if="n === 3"
                    :key="`width-${n.id}`"
                    width="100%">
                </v-responsive>
            </template>
        </v-row>

        <v-row justify="center">
            <v-col cols="8">
            <v-container class="max-width">
                <v-pagination v-model="currentPage" class="my-4" :length="total" @input="getCertificates"></v-pagination>
            </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // currentPage: 1,
            // total: 0,
            certificates: [],
            user_id: 0,
        }
    },
    methods: {
        async getCertificates() {
            await this.$http
                .get("/v1/certificate/user/"+this.user_id)
                .then((response) => {
                    this.certificates = response.data.data.certificates
                    // this.total = parseInt(response.data.data.total / 12) + 1
                })
            .catch((err) => console.error(err.response))
        },
        goToLink(e) {
            window.open(
                e,
                '_blank' // <- This is what makes it open in a new window.
            )
        },
    },
    mounted() {
        var data = JSON.parse(localStorage.getItem("user"))
        this.user_id = data.id
        this.getCertificates()
    }
}
</script> -->