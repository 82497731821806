<template>
    <div>
        <DesktopDetailAssesment v-if="!isMobile" />
        <MobileDetailAssesment v-else />
    </div>
</template>

<script>
import DesktopDetailAssesment from '@/components/Assesment/Desktop/DetailAssesment'
import MobileDetailAssesment from '@/components/Assesment/Mobile/DetailAssesment'
export default {
  components: {
    DesktopDetailAssesment,
    MobileDetailAssesment
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    this.$gtag.pageview(this.$route)
  }
}
</script>