<template>
  <div>
    <!-- <v-breadcrumbs :items="items"></v-breadcrumbs> -->
    <input
      type="file"
      ref="file"
      style="display: none"
      @input="(e) => uploadTask(e)"
      :accept="$utils.applyFormatFile()"
    />
    <Popup
      v-if="isAlert"
      isError
      :message="messageError"
      @close="() => (isAlert = false)"
    />
    <Loading v-if="isLoading" />
    <Modal
      v-if="isModalPresensi"
      @close="() => (isModalPresensi = false)"
      width="40%"
    >
      <h2 class="modal-title" style="text-align: left">
        Info tentang Presensi Kelas
      </h2>

      <div class="modal-body mt-5" style="text-align: left">
        <ol>
          <li>
            Presensi Awal/Hadir akan aktif dari
            <span class="font-bold"
              >{{ schedule.category === 4 ? 1: 6}} jam sebelum kelas berlangsung ({{ time.toleranceStart }})
              hingga 2 jam setelah dimulainya kelas ({{
                time.toleranceEnd
              }}).</span
            >
          </li>
          <li>
            Presensi Akhir/Keluar akan tertampil
            <b
              >setelah waktu kelas berakhir ({{ time.endTime }}) hingga
              23.59.</b
            >
            Apabila melewati batas waktu presensi sistem akan merekap presensi
            secara default dengan <b>status “Terkonfirmasi” pukul 23.59 WIB.</b>
          </li>
          <li>
            Peserta wajib mengisi Presensi Kelas Awal/Hadir & Akhir/Keluar
            sebagai <b>Data Kehadiran Peserta tiap sesi</b>.
          </li>
        </ol>
      </div>
    </Modal>
    <Modal
      v-if="isModalAktivitas"
      @close="() => (isModalAktivitas = false)"
      width="40%"
    >
      <h2 class="modal-title" style="text-align: left">
        Info tentang Aktivitas Kelas
      </h2>

      <div class="modal-body mt-5" style="text-align: left">
        <ol>
          <li>
            Peserta kelas Kartu Prakerja dapat bergabung dalam sesi webinar
            melalui tombol <b>Gabung Kelas</b>
          </li>
          <li>
            Tombol Gabung Kelas akan aktif apabila peserta
            <b>telah melakukan Presensi Awal/Hadir</b>
          </li>
          <li>
            Pastikan <b>memiliki akun Zoom</b> untuk mengikuti pelatihan
            berbasis Webinar.
          </li>
        </ol>
      </div>
    </Modal>
    <div v-if="isRejectedRequest" class="container-size">
      <div class="text-center mb-10" style="margin-top:150px"><img src="/img/other/ic_404.png" height="300"></div>
        <div class="text-center font-weight-bold mt-10"><span style="font-size:2.0rem">Access Denied</span></div>
        <div class="text-center mt-5 mb-15">
            <v-btn depressed color="#000080" class="pa-5" @click="$router.push(`/class/${param.class_id}/session`);">
                <span class="text-capitalize white--text font-weight-bold">Kembali ke detail kelasmu</span>
            </v-btn>
        </div>
    </div>
  <div class="container-custom" v-else>
    <div class="d-flex modified-breadcrumbs">
      <a :href="schedule.status ? '/active-class' : '/history-class'">{{
        schedule.status ? "Kelas Aktif" : "Riwayat Kelas"
      }}</a>
      <img src="/img/icons/ic_arrow_right.png" width="24px" height="24px" />
      <a :href="`/class/${param.class_id}/session`">Detail Kelas</a>
      <img src="/img/icons/ic_arrow_right.png" width="24px" height="24px" />
      <a href="" class="truncate-text">{{ schedule.name }}</a>
      <img src="/img/icons/ic_arrow_right.png" width="24px" height="24px" />
      <p>Sesi {{ this.param.session }}</p>
    </div>
    <div class="d-flex" style="margin-top: 45px; margin-left: 10px">
      <div>
        <img :src="schedule.image_url ? schedule.image_url : '/img/logo-hc.png'" width="100" height="100"
          style="border-radius: 16px" />
      </div>
      <div style="margin-left: 30px">
        <div class="container-label-top">
          <div class="label-top" style="background-color: #f1f6ff" v-if="schedule.category !== 4">
            {{
              $utils.categoryName(schedule.category)
            }}
          </div>
          <div class="badge" v-if="schedule.category === 4">
            <img src="/img/other/ic_prakerja_new.png" width="69" height="22" />
          </div>
          <div class="label-top" style="background-color: #faeceb">
            {{ schedule.learning_method | formatMethode }}
          </div>
        </div>

        <div>
          <h2>
            {{ schedule.name }}
          </h2>
        </div>

        <div class="d-flex" style="margin-top: 16px">
          <div class="box-info-card">
            <LevelIcon :width="24" :height="24" />
            <span class="box-info-card-span">
              {{ $utils.formatLevel(schedule.level) }}
            </span>
          </div>
          <div class="box-info-card" style="margin-left: 20px">
            <CalendarIcon :width="24" :height="24" />
            <span class="box-info-card-span">
              {{ schedule.dates }}, {{ schedule.hours }}
            </span>
          </div>
          <div class="box-info-card" style="margin-left: 20px">
            <MediaIcon :width="24" :height="24" />
            <span class="box-info-card-span">{{ $utils.formatMode(schedule.learning_method)  }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container-radius" v-if="schedule.status">
      <div class="d-flex">
        <div style="width: 82%">
          <div class="temp-1">
            <h2>Presensi Kelas</h2>
            <div class="temp-1 cursor-pointer" @click="openModal('PRESENSI')">
              <Information style="margin-left: 10px" />
            </div>
          </div>
          <span
            >Klik kehadiran pada saat {{ schedule.category === 4 ? 1: 6}} jam sebelum pelatihan dimulai dan
            setelah pelatihan berakhir</span
          >
        </div>
      </div>
      <div class="container-box-presensi">
				<div class="box-presensi">
					<p>Presensi Awal/Hadir</p>
					<div class="action-presensi">
						<div class="time-presensi">
							<span style="color: #7b7e8c" v-if="!isAbsen">{{
              emptyPresensi
            }}</span>
            <span v-else> {{ attendance.start_date }}</span>
						</div>
            <button disabled class="btn-inactive-presensi"  v-if="!isAbsen && time.validationStart > time.now && time.now < time.validationStart">
							<TimerThinIcon :width="24" :height="24" />
							<span>Presensi Belum Aktif</span>
						</button>
						<button
							@click="addAttendance"
              v-if="!isAbsen && !(time.validationStart > time.now && time.now < time.validationStart)"
							class="btn-confirmation-presensi">
							KONFIRMASI HADIR
						</button>
					</div>
				</div>
				<div class="box-presensi">
					<p>Presensi Akhir/Keluar</p>
					<div class="action-presensi">
						<div class="time-presensi">
							<span style="color: #7b7e8c" v-if="attendance.end_date === '-'">{{
              emptyPresensi
            }}</span>
            <span v-else> {{ attendance.end_date }}</span>
						</div>
						<button disabled class="btn-inactive-presensi"  v-if="isAbsen && !doneAbsen && time.now < time.validationEnd">
							<TimerThinIcon :width="24" :height="24" />
							<span>Presensi Belum Aktif</span>
						</button>
            <button
						@click="addAttendance"
            v-if="isAbsen && !doneAbsen && time.now > time.validationEnd"
							class="btn-confirmation-presensi">
							KONFIRMASI KELUAR
						</button>
					</div>
				</div>
			</div>
      <!-- <div>
        <div class="d-flex" style="margin-left: 40px">
          <h4 class="w-300">Presensi Awal/Hadir</h4>
          <h4 class="w-300">Presensi Akhir/Keluar</h4>
        </div>
        <div class="d-flex mt-3 bg-ghost-white absent">
          <div class="w-300">
            <span style="color: #7b7e8c" v-if="!isAbsen">{{
              emptyPresensi
            }}</span>
            <span v-else> {{ attendance.start_date }}</span>
          </div>
          <div class="w-300">
            <span style="color: #7b7e8c" v-if="attendance.end_date === '-'">{{
              emptyPresensi
            }}</span>
            <span v-else> {{ attendance.end_date }}</span>
          </div>

          <v-btn
            depressed
            color="#162CA2"
            style="padding: 0px 80px"
            class="btn-radius d-flex"
            @click="addAttendance"
            v-if="!isAbsen"
            :disabled="
              time.validationStart > time.now && time.now < time.validationStart
            "
          >
            <span class="text-capitalize font-weight-bold white--text"
              >KONFIRMASI MASUK</span
            >
          </v-btn>
          <v-btn
            depressed
            color="#B80F0A"
            style="padding: 0px 80px"
            class="btn-radius d-flex"
            @click="addAttendance"
            v-if="isAbsen && !doneAbsen && time.now > time.validationEnd"
          >
            <span class="text-capitalize font-weight-bold white--text"
              >KONFIRMASI KELUAR</span
            >
          </v-btn>
          <v-btn
            depressed
            style="background: white; padding: 0px 80px"
            class="btn-radius justify-center gray-button"
            v-if="isAbsen && !doneAbsen && time.now < time.validationEnd"
          >
            <div style="display: flex; align-items: center; gap: 8px">
              <Padlock />
              <span class="text-capitalize font-weight-bold">TERKUNCI</span>
            </div>
          </v-btn>
          <v-btn
            depressed
            style="padding: 0px 80px"
            class="btn-radius d-flex confirmed"
            v-if="isAbsen && doneAbsen"
          >
            <GrayCheck /><span
              class="text-capitalize font-weight-bold"
              style="margin-left: 10px"
              >TERKONFIRMASI</span
            >
          </v-btn>
        </div>
      </div> -->
    </div>
    <div v-if="param.session == 1" class="container-radius pt-6 pb-6">
      <h2>Evaluasi Pelatihan Awal</h2>
      <span>Wajib kerjakan soal Pre-Test sebelum kelas di mulai</span>
      <div
        class="bg-ghost-white d-flex"
        style="align-items: center; margin-top: 20px"
      >
        <div style="width: 100%">
          <h3>Pre-Test</h3>
          <span
            >Tes untuk mengukur kemampuan dasar sebelum mengikuti
            pelatihan</span
          >
        </div>
        <div v-if="schedule.status && isAssesmentAvailable">
        <button disabled class="btn-inactive-presensi-2"  v-if="!isAbsen && !isTester">
							<TimerThinIcon :width="24" :height="24" />
							<span>BELUM AKTIF</span>
						</button>
        <v-btn
          depressed
          v-else
          style="background: none"
          class="btn-stoke-blue justify-end gray-button"
          @click="goToCurrentPage('Pre Test', 1)">
          <span class="text-capitalize font-weight-bold">KERJAKAN</span>
        </v-btn>
      </div>
        <v-btn
          depressed
          style="padding: 0px 30px"
          class="btn-radius d-flex confirmed"
          v-else
          disabled
        >
          <GrayCheck /><span
            class="text-capitalize font-weight-bold white--text"
            style="margin-left: 10px"
            >SELESAI DIKERJAKAN</span
          >
        </v-btn>
      </div>
    </div>
    <div
      class="container-radius"
      v-if="
        (schedule.learning_method == 'Online' ||
          schedule.learning_method == 'Daring') &&
        schedule.status
      "
    >
      <div class="d-flex mt-3">
        <div style="width: 82%">
          <div class="temp-1">
            <div class="temp-1">
              <h2>Aktivitas Kelas</h2>
              <div
                class="temp-1 cursor-pointer"
                @click="openModal('AKTIVITAS')"
              >
                <Information style="margin-left: 10px" />
              </div>
            </div>
          </div>
          <span
            >Gabung aktivitas kelas pelatihan! Pastikan memiliki akun aplikasi Zoom/Google Meet untuk mengikuti kelas ini.</span
          >
        </div>
        <button disabled class="btn-inactive-presensi-2" style="background-color: #F7F9FD;"  v-if="(!isAbsen || isAssesmentAvailable) && !isTester">
							<TimerThinIcon :width="24" :height="24" />
							<span>BELUM AKTIF</span>
						</button>
        <v-btn
        color="#162CA2"
          depressed
          style="padding: 0px 80px"
          class="btn-radius d-flex btn-stoke-blue justify-end gray-button"
          @click="goToPage(schedule.learning_link)"
          v-else
        >
          <span class="text-capitalize font-weight-bold white--text">GABUNG KELAS</span>
        </v-btn>
      </div>
    </div>

    <div class="container-radius">
      <div class="d-flex">
        <div style="width: 100%">
          <h2>Materi Pelatihan</h2>
          <span
            >Gabung aktivitas kelas pelatihan! Pastikan memiliki akun aplikasi Zoom/Google Meet untuk mengikuti kelas ini.</span
          >
        </div>
        <button disabled class="btn-inactive-presensi-2" style="background-color: #F7F9FD;"  v-if="(!isAbsen || isAssesmentAvailable) && !isTester">
							<TimerThinIcon :width="24" :height="24" />
							<span>BELUM AKTIF</span>
						</button>
        <v-btn
          depressed
          style="background: white; padding: 0px 20px"
          class="btn-stoke-blue justify-end gray-button"
          @click="goToMateri"
          v-else
        >
          <span class="text-capitalize font-weight-bold">MATERI PELATIHAN</span>
        </v-btn>
      </div>
    </div>

    <div class="container-radius">
      <div class="d-flex" style="align-items: center">
        <h2 style="width: 100%">Evaluasi Pelatihan</h2>
        <div
          style="cursor: pointer"
          @click="isAssesmentHide = !isAssesmentHide"
        >
          <img
            :src="
              isAssesmentHide
                ? '/img/icons/ic_minus.png'
                : '/img/icons/ic_plus.png'
            "
            width="24px"
            height="24px"
          />
        </div>
      </div>

      <div v-if="isAssesmentHide">
        <span>Kerjakan semua tahapan dengan ketentuan di bawah ini :</span>
        <ul>
          <li>Wajib mengerjakan soal Pre-Test sebelum kelas di mulai.</li>
          <li>
            Soal Quiz wajib dikerjakan di setiap sesi pelatihan dengan batas
            nilai minimum 80 poin, sebagai syarat peserta dapat melanjutkan ke
            sesi berikutnya.
          </li>
          <li>Tugas Praktik Mandiri wajib dikerjakan pada tiap sesi.</li>
          <li>
            Soal Post Test wajib dikerjakan dan dapat diakses apabila seluruh
            rangkaian Tugas Praktik Mandiri dari sesi 1 sampai 4 selesai
            dikerjakan.
          </li>
        </ul>
        <div
          class="bg-ghost-white"
          style="margin-top: 20px"
          v-if="param.session <= schedule.total_quiz"
        >
          <div class="d-flex" style="align-items: center; margin-bottom: 20px">
            <div style="width: 100%">
              <h3>Formative Test : Quiz {{ param.session }}</h3>
              <span
                >Tes untuk mengukur kemampuan & kepahaman terhadap materi
                pelatihan</span
              >
            </div>
            <div v-if="schedule.status">
            <button disabled class="btn-inactive-presensi-2"  v-if="(tpmData.is_locked || isAssesmentAvailable || !isAbsen) && !isTester">
							<TimerThinIcon :width="24" :height="24" />
							<span>BELUM AKTIF</span>
						</button>
            <v-btn
              style="background: none"
              class="btn-stoke-blue justify-end gray-button"
              @click="goToCurrentPage('Quiz',tpmData.iteration)"
              depressed
              v-else
              >
              <span class="text-capitalize font-weight-bold">KERJAKAN</span>
            </v-btn>
          </div>
            <v-btn
              depressed
              style="padding: 0px 30px"
              class="btn-radius d-flex confirmed"
              v-else
              disabled
            >
              <GrayCheck /><span
                class="text-capitalize font-weight-bold white--text"
                style="margin-left: 10px"
                >SELESAI DIKERJAKAN</span
              >
            </v-btn>
          </div>
        </div>
        <div
          class="bg-ghost-white"
          style="align-items: center; margin-top: 20px"
          v-if="tpmData.instruction_tpm_description"
        >
          <div class="d-flex">
            <div style="width: 100%">
              <h2>Tugas Praktik Mandiri {{ tpmData.iteration }}</h2>
              <span style="max-width: 75%; display: inline-block">
                Tes evaluasi untuk mengetahui tingkat penyerapan ilmu melalui
                tugas praktik
              </span>
            </div>
            <div v-if="!tpmData.is_tpm_completed && schedule.status">
            <button disabled class="btn-inactive-presensi-2"  v-if="(!isAbsen || isAssesmentAvailable) && !isTester">
							<TimerThinIcon :width="24" :height="24" />
							<span>BELUM AKTIF</span>
						</button>
            <v-btn
              depressed
              style="background: white"
              class="btn-stoke-blue justify-end gray-button"
              @click="$refs.file.click()"
              v-else
            >
              <span class="text-capitalize font-weight-bold">UNGGAH</span>
            </v-btn>
          </div>
            <v-btn
            v-else
              depressed
              style="padding: 0px 80px; width: 290px;"
              class="btn-radius d-flex confirmed"
              
              disabled
            >
              <GrayCheck /><span
                class="text-capitalize font-weight-bold white--text"
                style="margin-left: 10px"
                >SELESAI DIKERJAKAN</span
              >
            </v-btn>
            
          </div>
          <div>
            <div class="mt-3">
              <b>Instruksi Tugas Praktik Mandiri: {{ tpmData.iteration }}</b>
              <p v-html="tpmData.instruction_tpm_description"></p>
              <p class="text-red">
                *File yang dapat di upload adalah JPG/JPEG, PNG, DOC, DOCX, XLS,
                XLSX, TXT, PDF, PPT, PPTX <br />
                dan maksimal ukuran file 2 MB
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="param.session >= schedule.total_quiz"
          style="align-items: center; margin-top: 20px"
          class="bg-ghost-white d-flex"
        >
          <div style="width: 100%">
            <h3>Post-Test</h3>
            <span
              >Tes evaluasi untuk mengetahui tingkat penyerapan ilmu & pemahaman
              seluruh materi pelatihan</span
            >
          </div>
          <div v-if="schedule.status">
          <button disabled class="btn-inactive-presensi-2"  v-if="(!isAbsen || isAssesmentAvailable) && !isTester">
							<TimerThinIcon :width="24" :height="24" />
							<span>BELUM AKTIF</span>
						</button>
          <v-btn
            style="background: none"
            class="btn-stoke-blue justify-end gray-button"
            @click="goToCurrentPage('Post Test', 1)"
            depressed
            v-else
          >
            <span class="text-capitalize font-weight-bold">KERJAKAN</span>
          </v-btn>
        </div>
          <v-btn
            depressed
            style="padding: 0px 30px"
            class="btn-radius d-flex confirmed"
            v-else
            disabled
          >
            <GrayCheck /><span
              class="text-capitalize font-weight-bold white--text"
              style="margin-left: 10px"
              >SELESAI DIKERJAKAN</span
            >
          </v-btn>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import moment from "moment";
import Information from "@/assets/icons/Information.vue";
import Modal from "@/components/Modal.vue";
import GrayCheck from "@/assets/icons/GrayCheck.vue";
import Loading from "../../others/Loading.vue";
import Popup from "../../others/Popup.vue";
import LevelIcon from "@/assets/icons/Level.vue";
import CalendarIcon from "@/assets/icons/Calendar.vue";
import MediaIcon from "@/assets/icons/Media.vue";
import TimerThinIcon from '@/assets/icons/TimerThin.vue';

export default {
  name: "DDetailClassPrakerja",
  components: {
    Information,
    Modal,
    GrayCheck,
    Loading,
    Popup,
    LevelIcon,
    CalendarIcon,
    MediaIcon,
    TimerThinIcon
  },
  data() {
    return {
      isAssesmentHide: true,
      isModalPresensi: false,
      isModalAktivitas: false,
      isAlert: false,
      messageError: "",
      isLoading: false,
      isTester: false,
      isAbsen: false,
      isRejectedRequest: false,
      doneAbsen: false,
      emptyPresensi: "Belum terkonfirmasi",
      tpmData: {},
      time: {
        toleranceStart: "",
        toleranceEnd: "",
        endTime: "",
        validationStart: "",
        validationEnd: "",
        now: new Date(),
      },
      items: [
        {
          text: "Kelas Aktif",
          disabled: false,
          href: "/active-class",
        },
        {
          text: "Detail Kelas",
          disabled: true,
        },
        {
          text: "Sesi 1",
          disabled: true,
        },
      ],
      param: {
        class_id: this.$route.params.id,
        session: this.$route.params.session,
        done: this.$route.query.done,
      },
      attendance: {},
      schedule: {
        final_project: {},
      },
      redeem_code: "",
      data_assasment: {},
      assesment: {},
      userID: this.$decode_token["id"],
      isAssesmentAvailable: false,
      date: moment(new Date()).format("ddd, DD MMM YYYY"),
    };
  },
  methods: {
    async getSchedule() {
      this.isLoading = true
      try {
        this.redeem_code = await this.$utils.getRedeem(this.param.class_id)
        const response = await this.$http.get(`/v2/user-schedule/detail?user_id=${this.userID}&class_id=${this.param.class_id}`)  
        this.schedule = response.data.data.schedule;
        this.tpmData = this.schedule.sessions.find((item) => item.iteration == this.param.session);
        this.validationChecking();
        this.isLoading = false
      } catch{
        this.$router.push("/home");
        return
      }
      const [startTime, endTime] = this.schedule.hours.split(" - ");
      const startTimeString = startTime.replace(".", ":");

      const originalDate = new Date(
        `${moment(this.time.now).format("YYYY-MM-DD")}T${startTimeString}:00`
      );

      let oneHourBeforeDate = new Date()
      if (this.schedule.category === 4) {
        oneHourBeforeDate = new Date(
          originalDate.getTime() - 60 * 60 * 1000
        );  
      } else {
        oneHourBeforeDate = new Date(
          originalDate.getTime() - 360 * 60 * 1000
        );  
      }
      
      const twoHoursAfterDate = new Date(
        originalDate.getTime() + 120 * 60 * 1000
      );
      this.time.validationStart = oneHourBeforeDate;
      this.time.validationEnd = twoHoursAfterDate;

      const hours = oneHourBeforeDate.getHours().toString().padStart(2, "0");
      const minutes = oneHourBeforeDate
        .getMinutes()
        .toString()
        .padStart(2, "0");
      this.time.toleranceStart = `${hours}.${minutes} WIB`;

      const hours2 = twoHoursAfterDate.getHours().toString().padStart(2, "0");
      const minutes2 = twoHoursAfterDate
        .getMinutes()
        .toString()
        .padStart(2, "0");
      this.time.toleranceEnd = `${hours2}.${minutes2} WIB`;

      this.time.endTime = endTime;
    },
    async uploadTask(file) {
      const data = this.$utils.getFile(file);
      if (data[1] > 2) {
        this.isAlert = true;
        this.messageError = "Ukuran file tidak boleh lebih besar dari 2 MB";
        return;
      }

      this.isLoading = true;
      const formData = new FormData();
      formData.append("attachment", data[0]);

      const res_upload = await this.$crm_http.post(
        "/v1/upload-file",
        formData,
        {
          headers: {"content-type": "multipart/form-data"},
        }
      );

      const attachment_url = this.$utils.filterLinkClickup(
        res_upload.data.data.upload_image_response.url_w_query
      );
      await this.$http
        .post("/v1/user-assesment/prakerja", {
          assessment_id: this.data_assasment.assesment_id,
          redeem_code: this.redeem_code,
          sequence: Number(this.param.session),
          url_file: attachment_url,
          scope: "tpm",
          replace: this.schedule.category === 4
        })

        .then(() => {
          this.getSchedule();
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.isAlert = true;
          this.messageError = err.response.data.data;
          if (this.messageError == "redeem code tidak ditemukan" ||this.messageError == "Gagal verifikasi, kode tidak ditemukan.") {
            this.$router.push(`/class/${this.param.class_id}/verification/${this.param.session}`);
          }
        });
    },
    async addAttendance() {
      await this.$http
        .post("/v1/attendance/student", {
          user_id: parseInt(this.userID),
          class_id: parseInt(this.param.class_id),
          now: new Date(),
          sequence: Number(this.param.session),
        })
        .then(() => {
          this.getAttendance();
        })
        .catch((err) => console.error(err.response));
    },
    async getAttendance() {
      await this.$http
        .get(
          `/v1/user-attendance?user_id=${this.userID}&class_id=${this.param.class_id}&sequence=${this.param.session}`
        )
        .then((response) => {
          this.attendance = response.data.data.attendance;
          if (this.attendance.start_date == "00:00") {
            this.attendance.start_date = "-";
          } else {
            this.attendance.start_date =
              response.data.data.attendance.start_date + " WIB";
          }

          if (this.attendance.end_date == "00:00") {
            this.attendance.end_date = "-";
          } else {
            this.attendance.end_date =
              response.data.data.attendance.end_date + " WIB";
          }
          this.isAbsen = this.attendance.start_date !== "-";
          if (this.isAbsen) {
            this.doneAbsen = this.attendance.end_date !== "-";
          }
        })
        .catch((err) => console.error(err.response));
    },
    async getAssesmentByUserClass() {
      await this.$http
        .get(
          `/v1/user-assesment/detail?user_id=${this.userID}&class_id=${this.param.class_id}`
        )
        .then((response) => {
          if (response.data.data == "NO_DATA") {
            this.isAssesmentAvailable = true;
          } else {
            this.assesment = response.data.data;
            this.data_assasment = response.data.data.assesment;
          }
        })
        .catch((err) => console.error(err.response));
    },
    goToPage(e) {
      window.open(e);
    },
    goToMateri() {
        if (this.tpmData.material_link) {
          window.open(this.tpmData.material_link);
          return;
        }
        window.open(this.schedule.rubrik_url);
    },
    goToCurrentPage(e, g) {
      window.location.href =
        "/question/" + this.param.class_id + "?c=" + e + "&g=" + g;
    },
    openModal(type) {
      if (type === "PRESENSI") {
        this.isModalPresensi = true;
      } else if (type === "AKTIVITAS") {
        this.isModalAktivitas = true;
      }
    },
    validationChecking() {
      if (!this.schedule.status || this.isTester) return;
      if (this.param.done && this.tpmData.is_locked) {
        this.$router.push(`/class/${this.param.class_id}/session`);
        return;
      }

      if (this.schedule.category === 4) {
      if (!this.redeem_code && !this.param.done) {
        this.$router.push(`/class/${this.param.class_id}/session`);
        return
      }
      if (!this.param.done) {
        this.checkStatus();
      }
    }
    },
    async checkStatus() {
      this.isLoading = true
      try {
        const res = await this.$http.get(
          `/v1/prakerja/status?redeem_code=${this.redeem_code}&sequence=${this.param.session}&class_id=${
            this.param.class_id
          }&email=${this.$decode_token["email"]}&user_id=${
            this.$decode_token["id"]
          }`
        );
        this.isLoading = false
        const data = res.data.data;
        if (!data.attendance_status || this.param.session > res.sequence) {
          this.verificationCode()
        }
      } catch (error) {
        this.isAlert = true
        this.messageError = error.response.data.data             
        this.isRejectedRequest = true
        this.isLoading = false
        if (this.messageError == "redeem code tidak ditemukan" || this.messageError == "Gagal verifikasi, kode tidak ditemukan.") {
          this.$router.push(`/class/${this.param.class_id}/verification/${this.param.session}`); 
        }
      }
    },
    async verificationCode() {
      this.isLoading = true
        const data = {
                redeem_code: this.redeem_code,
                class_id: this.param.class_id,
                session: this.param.session
            }
            try {
                const res = await this.$web_http.post(`/v1/prakerja/url-generate`, {
                    redeem_code: this.redeem_code,
                    email: this.$decode_token["email"],
                    class_id: Number(this.param.class_id),
                    user_id: this.$decode_token["id"],
                    sequence: Number(this.param.session)
                })
                this.$utils.setLocalPrakerja(data)
                window.location.href = res.data.data.url
            } catch (error) {
                this.isAlert = true
                this.messageError = error.response.data.data             
                this.isRejectedRequest = true
                this.isLoading = false
            }
    },
    checkingTester() {
      const listIdTester = this.$utils.listIdTester()
      if (listIdTester.find((item) => item == this.userID)) {
        this.isTester = true
      } else {
        this.isTester = false
      }
    }
  },
  mounted() {
    if (this.$route.query.status == "tamat") {
      this.items[0].text = "Riwayat Kelas";
      this.items[0].href = "/history-class";
      this.items[1].text = "Detail Riwayat Kelas";
    }
    this.checkingTester()
    this.getSchedule();
    this.getAssesmentByUserClass();
    this.getAttendance();
  },
};
</script>

<style scoped>
.container-box-presensi,
.container-box-evaluation {
	margin-top: 32px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}
.box-presensi {
	padding: 24px 40px;
	border: 1px solid #d3d6e1;
	border-radius: 16px;
	max-width: 60%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.box-presensi > p {
	color: #36373f;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	margin: 0;
}
.action-presensi {
	display: flex;
	align-items: center;
	gap: 16px;
}
.time-presensi {
	width: 220px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
	border-radius: 16px;
	background-color: #f7f9fd;
	font-family: 'Poppins', sans-serif;
	color: #7b7e8c;
	text-align: center;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}
.btn-confirmation-presensi {
	background-color: #162ca2;
	border-radius: 12px;
	width: 220px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
	color: #ffffff;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	letter-spacing: 1px;
}
.btn-confirmation-presensi:hover {
	background-color: #283ebc;
}
.btn-inactive-presensi-2 {
  width: 200px;
  height: 60px;
	display: flex;
	align-items: center;
	gap: 14px;
  background-color: white;
  border-radius: 15px;
	
	justify-content: center;
}
.btn-inactive-presensi-2 span {
	color: #7b7e8c;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 16px;
}
.btn-inactive-presensi {
	width: 200px;
	display: flex;
	align-items: center;
	gap: 4px;
	padding: 8px 13px;
	justify-content: center;
}
.btn-inactive-presensi span {
	color: #7b7e8c;
	font-family: 'Poppins', sans-serif;
	font-weight: 400;
	font-size: 13px;
	line-height: 18px;
}

.btn-activity,
.btn-materi,
.btn-test,
.btn-test-inactive {
	border: 1px solid #162ca2;
	border-radius: 12px;
	padding: 12px 47px;
	width: 210px;
	display: flex;
	align-items: center;
	background-color: white;
	justify-content: center;
	gap: 8px;
}
.btn-activity:hover,
.btn-materi:hover,
.btn-test:hover {
	background-color: #f5f6ff;
}
.btn-activity span,
.btn-materi span,
.btn-test span {
	color: #162ca2;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
}
.absent {
  align-items: center;
  justify-content: center;
  color: #36373f;
  padding: 20px 40px;
}


.badge {
  display: flex;
  background-color: white;
  border-radius: 20px;
  height: 30px;
  padding: 0px 16px;
  align-items: center;
}
.container-label-top {
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
}

.label-top {
  background-color: #f1f6ff;
  padding: 5px 12px;
  border-radius: 24px;
  color: #36373f;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
}

.box-info-card {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.title-scrumb {
  color: #7b7e8c;
  font-weight: 400;
}

.container-radius > div {
  margin-bottom: 20px; /* Adjust the value according to the desired space between div elements */
}

.modified-breadcrumbs {
  margin-top: -40px;
  margin-left: 10px;
}
.modified-breadcrumbs a {
  text-decoration: none;
  color: #162ca2;
}

.modified-breadcrumbs p {
  color: #575966;
}
.w-300 {
  width: 300px;
}
.temp-1 {
  display: flex;
  align-items: center;
}

.truncate-text {
  max-width: 20ch; /* Set the maximum width equivalent to 20 characters */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-radius.confirmed:disabled {
  background-color: #ffffff !important;
  color: #575966 !important;
  font-size: 16px;
}

.btn-radius.confirmed:disabled .white--text {
  color: #575966 !important;
}

.btn-submit {
  margin: auto;
  display: block;
  font-size: 16px;
  border-radius: 14px !important;
}
</style>
