<template>
    <div>
        <DesktopProfile v-if="!isMobile" />
        <MobileProfile v-else />
    </div>
</template>

<script>
import DesktopProfile from '@/components/Profile/Desktop/Profile'
import MobileProfile from '@/components/Profile/Mobile/Profile'
export default {
  components: {
    DesktopProfile,
    MobileProfile
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    this.$gtag.pageview(this.$route)
  }
}
</script>