<template>
  <div>
      <DesktopHome v-if="!isMobile" />
      <MobileHome v-else />
  </div>
</template>

<script>
import DesktopHome from '@/components/Home/Desktop/Home'
import MobileHome from '@/components/Home/Mobile/Home'
export default {
  components: {
    DesktopHome,
    MobileHome
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    this.$gtag.pageview(this.$route);
  }
}
</script>