<template>
    <div>
        <DesktopPenilaianClassEvent  />
    </div>
</template>

<script>
import DesktopPenilaianClassEvent from '@/components/PenilaianEvent/Desktop/PenilaianClassEvent.vue'

export default {
  components: {
    DesktopPenilaianClassEvent,
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
  }
}
</script>