<template>
    <div>
        <DesktopActiveClass v-if="!isMobile" />
        <MobileActiveClass v-else />
    </div>
</template>

<script>
import DesktopActiveClass from '@/components/ActiveClass/Desktop/ActiveClass'
import MobileActiveClass from '@/components/ActiveClass/Mobile/ActiveClass'
export default {
  components: {
    DesktopActiveClass,
    MobileActiveClass
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    this.$gtag.pageview(this.$route)
  }
}
</script>