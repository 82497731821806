<template>
    <div>
        <DesktopSetting v-if="!isMobile" />
        <MobileSetting v-else />
    </div>
</template>

<script>
import DesktopSetting from '@/components/Setting/Desktop/Setting'
import MobileSetting from '@/components/Setting/Mobile/Setting'
export default {
  components: {
    DesktopSetting,
    MobileSetting
  },
  data() {
    return{
      isMobile: false
    }
  },
  methods: {
      handleView() {
          this.isMobile = screen.width <= 990
      }
  },
  beforeMount() {
    this.handleView()
    this.$gtag.pageview(this.$route)
  }
}
</script>